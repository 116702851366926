import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "./Global"
import { Small, P } from "./Text"

const styles = {
  default: tw`relative z-1 block w-full font-sans font-medium text-base md:text-xl text-grey-darkest shadow-none bg-transparent border-b pr-8 border-grey pt-5.75 md:pt-6.75 pb-2 appearance-none outline-none focus:outline-none rounded-none transition-colors duration-200`,
  disabled: tw`pointer-events-none opacity-50`,
  readOnly: tw`pointer-events-none`,
  inverted: tw`text-white border-white`,
  invisible: tw`absolute inset-0 opacity-0 cursor-pointer`,
  errors: tw`border-red`,
  full: tw`block w-full`,
  button: {
    default: tw`absolute right-0 text-purple-dark cursor-pointer focus:outline-none`,
    icon: tw`mb-3.5 w-3.5 md:w-5`,
  },
  label: {
    default: tw`font-sans text-sm text-grey`,
    inverted: tw`text-white`,
    resting: tw`absolute block top-0 left-0 opacity-0 invisible transform translate-y-8 transition-all duration-200 pointer-events-none`,
    active: tw`translate-y-0 opacity-100 visible`,
    srOnly: tw`sr-only`,
  },
  placeholder: {
    resting: tw`text-grey opacity-100 transform translate-y-0 pointer-events-none scale-100 transition-all duration-200`,
    inverted: tw`text-white`,
    active: tw`opacity-0 -translate-y-8 -translate-x-1/2 scale-50`,
  },
  toggle: {
    default: tw`mb-3.5 absolute right-0 z-1`,
    icon: tw`w-3.5 md:w-5`,
  },
  valid: {
    resting: tw`w-3.5 md:w-5 pb-3.5 absolute right-0 invisible opacity-0 transform scale-75 pointer-events-none transition-all duration-200`,
    inverted: tw`text-white`,
    active: tw`visible opacity-100 scale-100`,
  },
  select: tw`relative flex items-end`,
  svg: {
    drawerInput: tw`hidden`,
  },
}

export const StyledForm = styled.form`
  ${styles.full}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const StyledLabel = styled.label`
  ${styles.label.default}
  ${({ srOnly }) => srOnly && styles.label.srOnly}
`
export const StyledSrLabel = tw.label`sr-only visible! opacity-100!`
export const StyledErrors = styled(Small)`
  ${tw`mt-2 text-left text-red w-full`}
  ${({ inverted }) => inverted && styles.inverted}
`
export const StyledErrorCentered = styled(Small)`
  ${tw`text-center text-red w-full -mt-4`}
`
export const StyledInputButton = styled.button`
  ${styles.button.default}

  svg {
    ${styles.button.icon}
  }
`
export const StyledInputWrapper = styled.div`
  ${({ full }) => full && styles.full}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${tw`relative flex items-end`}

  input, textarea {
    ${tw`block w-full`}
    ${({ drawerInput }) => drawerInput && tw`text-base font-normal resize-none`}
    ${({ drawerInput }) =>
      drawerInput &&
      css`
        border: 1px solid #f2a34e;
        padding: 1rem;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.05);
        height: 105px;
        max-height: 105px;
      `}
  }
  label {
    ${styles.label.resting}
  }

  &:focus-within {
    label {
      ${styles.label.active}
    }

    input,
    textarea {
      ::placeholder,
      ::-webkit-input-placeholder {
        ${styles.placeholder.active}
      }
      ::-moz-placeholder {
        ${styles.placeholder.active}
      }
    }
  }

  ${({ errors, drawerInput }) =>
    !(errors?.length > 0) &&
    drawerInput &&
    css`
      svg {
        ${styles.svg.drawerInput}
      }
    `}
`
export const StyledDateWrapper = styled.div`
  ${tw`absolute bg-white z-1 right-0 bottom-0 text-grey-darkest rounded shadow invisible opacity-0 transform scale-90 translate-y-6 transition-all duration-200`}
  ${({ active }) => active && tw`visible opacity-100 scale-100 translate-y-0`}

  .DayPicker-wrapper {
    ${tw`relative flex-row pb-4 select-none focus:outline-none`}
  }

  .DayPicker-Months {
    ${tw`flex flex-wrap justify-center`}
  }

  .DayPicker-Month {
    ${tw`table mt-4 mx-4 border-collapse select-none`}
  }

  .DayPicker-NavButton {
    ${tw`absolute top-4 right-6 left-auto inline-block mt-0.5 w-3.5 h-3.5 mt-1.5 bg-center bg-no-repeat bg-contain text-orange cursor-pointer focus:outline-none`}
  }

  .DayPicker-NavButton:hover {
    ${tw`opacity-75`}
  }

  .DayPicker-NavButton--prev {
    ${tw`mr-8`}
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.245 1.958L15.758.472a1.6 1.6 0 00-2.27 0L.472 13.482a1.6 1.6 0 000 2.27l13.016 13.016a1.6 1.6 0 002.27 0l1.487-1.486a1.609 1.609 0 00-.027-2.297L9.15 17.298h19.243c.89 0 1.607-.716 1.607-1.607V13.55c0-.89-.716-1.607-1.607-1.607H9.15l8.068-7.687c.656-.623.67-1.66.027-2.297z' fill='%23b757ac'/%3E%3C/svg%3E%0A");
  }

  .DayPicker-NavButton--next {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.755 1.958L14.242.472a1.6 1.6 0 012.27 0l13.016 13.01a1.6 1.6 0 010 2.27L16.512 28.768a1.6 1.6 0 01-2.27 0l-1.487-1.486a1.609 1.609 0 01.027-2.297l8.068-7.687H1.607c-.89 0-1.607-.716-1.607-1.607V13.55c0-.89.716-1.607 1.607-1.607H20.85l-8.068-7.687a1.597 1.597 0 01-.027-2.297z' fill='%23b757ac'/%3E%3C/svg%3E%0A");
  }

  .DayPicker-NavButton--interactionDisabled {
    ${tw`hidden`}
  }

  .DayPicker-Caption {
    ${tw`table-caption mb-2 px-2 text-left`}
  }

  .DayPicker-Caption > div {
    ${tw`font-semibold text-lg`}
  }

  .DayPicker-Weekdays {
    ${tw`table-header-group mt-4`}
  }

  .DayPicker-WeekdaysRow {
    ${tw`table-row`}
  }

  .DayPicker-Weekday {
    ${tw`table-cell p-2 text-grey-dark text-center text-sm`}
  }

  .DayPicker-Weekday abbr[title] {
    ${tw`border-b-0 no-underline`}
  }

  .DayPicker-Body {
    ${tw`table-row-group`}
  }

  .DayPicker-Week {
    ${tw`table-row`}
  }

  .DayPicker-Day {
    ${tw`table-cell p-2 rounded-full align-middle text-center cursor-pointer hover:text-orange-light focus:outline-none`}
  }

  .DayPicker-WeekNumber {
    ${tw`table-cell p-2 min-w-4 border-r border-grey-lighter text-grey-dark text-xs align-middle text-right cursor-pointer focus:outline-none`}
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    ${tw`cursor-default`}
  }

  .DayPicker-Day--today {
    ${tw`text-orange font-bold`}
  }

  .DayPicker-Day--selected {
    ${tw`text-purple-dark font-bold`}
  }

  .DayPicker-Day--disabled {
    ${tw`text-grey-light cursor-default pointer-events-none`}
  }
`
export const StyledDate = styled.p`
  ${styles.default}
  ${tw`pointer-events-none`}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ errors }) => errors?.length > 0 && styles.errors}
  ${({ full }) => full && styles.full}
  ${({ inverted }) => inverted && styles.inverted}
`
export const StyledInput = styled.input`
  ${styles.default}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ errors }) => errors?.length > 0 && styles.errors}
  ${({ full }) => full && styles.full}
  ${({ inverted }) => inverted && styles.inverted}
  ${({ invisible }) => invisible && styles.invisible}

  & + div {
    label {
      ${({ inverted }) => inverted && styles.label.inverted}
    }
  }

  & + div {
    ${tw`absolute inset-0 flex items-end`}

    > svg {
      ${styles.valid.resting}
      ${({ iconColour }) => iconColour && GlobalStyles.colour[iconColour]}
      ${({ inverted }) => inverted && styles.valid.inverted}
    }

    > button {
      ${styles.toggle.default}

      svg {
        ${styles.toggle.icon}
      }
    }
  }

  &:not(:placeholder-shown) {
    & + div {
      label {
        ${styles.label.active}
      }
    }
  }
  ${({ errors }) =>
    !(errors?.length > 0) &&
    css`
      &:required:valid {
        & + div {
          svg {
            ${styles.valid.active}
          }
        }
      }
    `}

  ::placeholder,
  ::-webkit-input-placeholder {
    ${styles.placeholder.resting}
    ${({ inverted }) => inverted && styles.placeholder.inverted}
  }
  ::-moz-placeholder {
    ${styles.placeholder.resting}
    ${({ inverted }) => inverted && styles.placeholder.inverted}
  }
`
export const StyledTextarea = styled.textarea`
  ${styles.default}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ errors }) => errors?.length > 0 && styles.errors}
  ${({ full }) => full && styles.full}
  ${({ inverted }) => inverted && styles.inverted}
  ${tw`h-24 focus:h-52 resize-none transition-all`}

  & + div {
    label {
      ${({ inverted }) => inverted && styles.label.inverted}
    }
  }

  & + div {
    ${tw`absolute inset-0 flex items-end pointer-events-none`}

    svg {
      ${styles.valid.resting}
      ${({ inverted }) => inverted && styles.valid.inverted}
    }
  }

  &:not(:placeholder-shown) {
    & + div {
      label {
        ${styles.label.active}
      }
    }
  }
  ${({ errors }) =>
    !(errors?.length > 0) &&
    css`
      &:required:valid {
        ${tw`h-52`}
        & + div {
          svg {
            ${styles.valid.active}
          }
        }
      }
    `}

  ::placeholder,
  ::-webkit-input-placeholder {
    ${styles.placeholder.resting}
    ${({ inverted }) => inverted && styles.placeholder.inverted}
  }
  ::-moz-placeholder {
    ${styles.placeholder.resting}
    ${({ inverted }) => inverted && styles.placeholder.inverted}
  }
`
export const StyledSelectWrapper = styled.div`
  ${({ full }) => full && styles.full}
  ${styles.select}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}

  label {
    ${styles.label.resting}
  }

  svg {
    ${styles.valid.resting}
    ${styles.valid.active}
  }
`
export const StyledSelect = styled.select`
  ${styles.default}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ errors }) => errors?.length > 0 && styles.errors}
  ${({ inverted }) => inverted && styles.inverted}
  ${tw`text-grey pr-12 cursor-pointer`}

  &:required:valid {
    ${tw`text-grey-darkest`}
    ${({ inverted }) => inverted && tw`text-white`}

    & + div {
      label {
        ${styles.label.active}
      }
    }
  }
`
export const StyledSelectOption = tw.option``
export const StyledCheckboxWrapper = styled(StyledInputWrapper)`
  ${tw`items-center`}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ readOnly }) => readOnly && styles.readOnly}
`
export const StyledCheckbox = styled.input`
  ${tw`absolute cursor-pointer opacity-0 w-full h-full appearance-none outline-none focus:outline-none rounded-none`}

  & + div {
    ${tw`relative flex-shrink-0 flex items-center justify-center h-5 md:h-6 w-5 md:w-6 mr-2.5 md:mr-3 bg-white rounded border border-grey-darker group-hover:border-orange pointer-events-none transition-all duration-200`}

    > div {
      ${tw`absolute text-white pointer-events-none transform scale-0 transition-all duration-200`}
    }
  }

  &:checked + div {
    ${tw`bg-grey-dark border-grey-dark text-white group-hover:border-grey-dark`}

    & + div {
      ${tw`text-grey-darkest`}
    }

    > div {
      ${tw`scale-100`}
    }
  }
`
export const StyledCheckboxLabel = tw(P)`leading-none`
export const StyledCheckboxContent = tw.div`flex-1 pointer-events-none`
